import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import {
  Flex,
  Box,
  Heading,
} from '@chakra-ui/react'
import { getImage } from 'gatsby-plugin-image';
import { PrismicRichText } from '@prismicio/react'
import { CustomLink } from '../utils/customLink'
import PageHero from '../components/PageHero/PageHero'
import MobilePageHero from '../components/PageHero/MobilePageHero'
import SliceZone from '../components/SliceZone/SliceZone'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import Sidebar from '../components/Sidebar/Sidebar'
import MooseLottie from '../components/Footer/Moose/MooseLottie'
import NewsCard from '../components/NewsCards/NewsCard'


const DepartmentPageTemplate = (props) => {
      // Explicitly delcare props and destructure the other properties:
      const { location, data, ...rest } = props;
      const pageLocation = location.pathname

  if (!data) return null; 

  const document = data.prismicDepartmentPage.data
  const backgroundImage = getImage(document.hero_image.localFile)
  const image = getImage(backgroundImage);

  const menuItems = null || document.sidebar_menu.document?.data.body

  const blogItems = data.allPrismicNews.edges;
  
  const planningAndZoningItem = blogItems.filter(item => item.node.tags.includes('Planning and Zoning'));
  const publicServiceAnnouncementItem = blogItems.filter(item => item.node.tags.includes('Public Service Announcement'));
  const publicNoticeItem = blogItems.filter(item => item.node.tags.includes('Public Notice'));
  const newsItem = blogItems.filter(item => item.node.tags.includes('News'));
  const eventItem = blogItems.filter(item => item.node.tags.includes('Event'));

  console.log(newsItem)
 
  return (
    <Layout>
      <Seo title={document.page_title.text}  />

<PageHero 
  heroImage={image}
  pageTitle={document.page_headline?.text}
  photoCredit={document.hero_image.copyright}
/>
<MobilePageHero 
        heroImage={image}
        photoCredit={document.hero_image.copyright}
      ></MobilePageHero>
      <Box position="relative" zIndex="1">
      <Flex w="100%" h="100%" flexWrap="wrap" flexDirection={{base: 'column-reverse', lg: 'row'}}>
        <Box w={{base: '100%', lg: '25%'}}>
              <Sidebar
                menuHeading={menuItems && document.sidebar_menu.document.data.menu_title.text}  
                menuItems={menuItems}
                pageLocation={pageLocation}
              />
           </Box>
           <Box w={{base: '100%', lg: '75%'}} bg="white" p="8" mb={{base: '4rem', md: '36rem'}}>
            <Heading as="h2" variant="page-title">
              {document.page_title.text}
            </Heading>
            <Heading as="h3" fontSize="2rem" variant="page-subtitle">
              {document.subtitle.text}
            </Heading>

            <PrismicRichText 
    field={document.page_content.richText} components={{
      hyperlink: ({children, node}) => (
        <CustomLink node={node}>{children}</CustomLink>
      ),
    }}/>
            <SliceZone sliceZone={document.body}/>


            {pageLocation === '/departments/planning-and-building' 
              ? (<Flex flexWrap="wrap">
                  {planningAndZoningItem.map((item, index) => {
                    return (
                      <NewsCard
                        image={getImage(item.node.data.featured_image.localFile)}
                        key={index}
                        title={item.node.data.post_title.text}
                        excerpt={item.node.data.post_excerpt.text}
                        url={item.node.url}
                        tags={item.node.tags}
                      />
                    )
                  })}
                </Flex>) 
              : pageLocation === '/departments/public-works' ? 
                (<Flex flexWrap="wrap">
                {publicServiceAnnouncementItem.map((item, index) => {
                  return (
                    <NewsCard
                      image={getImage(item.node.data.featured_image.localFile)}
                      key={index}
                      title={item.node.data.post_title.text}
                      excerpt={item.node.data.post_excerpt.text}
                      url={item.node.url}
                      tags={item.node.tags}
                    />
                  )
                })}
              </Flex>)
              : pageLocation === '/departments/public-notices' ? 
              (<Flex flexWrap="wrap">
              {publicNoticeItem.map((item, index) => {
                return (
                  <NewsCard
                    image={getImage(item.node.data.featured_image.localFile)}
                    key={index}
                    title={item.node.data.post_title.text}
                    excerpt={item.node.data.post_excerpt.text}
                    url={item.node.url}
                    tags={item.node.tags}
                  />
                )
              })}
            </Flex>)
            : null
            }
      </Box>
      <MooseLottie />
        </Flex>
      </Box>
      
    </Layout>
  )
}



    export const query = graphql`
    query DepartmentPageQuery($uid: String!) {
      prismicDepartmentPage(uid: {eq: $uid}) {
        _previewable
        uid
        type
        data {
          page_headline {
            text
          }
          page_title {
            text
          }
          hero_image {
            alt
            copyright
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
          page_content {
            richText
          }
          subtitle {
            text
          }
          sidebar_menu {
            document {
              ... on PrismicSidebarNavigation {
                id
                data {
                  menu_title {
                    text
                  }
                  body {
                    ... on PrismicSidebarNavigationDataBodySidebarNavAccordionItem {
                      id
                      slice_type
                      primary {
                        title_link {
                          url
                        }
                        navigation_group_title {
                          text
                        }
                      }
                      items {
                        link {
                          url
                        }
                        item_title {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          body {
            ... on PrismicDepartmentPageDataBodyContactCard {
              id
              slice_type
              primary {
                department_contact_information {
                  text
                }
              }
              items {
                contact_title {
                  text
                }
                contact_phone {
                  text
                }
                contact_name {
                  text
                }
                contact_email {
                  text
                }
                contact_extension
              }
            }
            ... on PrismicDepartmentPageDataBodyBidRfpProjectAccordion {
              id
              slice_type
              primary {
                accordion_title {
                  text
                }
                accordion_subtitle {
                  text
                }
              }
              items {
                item_title {
                  text
                }
                item_description {
                  richText
                }
                table {
                  richText
                  text
                }
                accordion_item_id {
                  text
                }
              }
            }
            ... on PrismicDepartmentPageDataBodyAlignedPhoto {
              id
              slice_type
              primary {
                align
                image_caption {
                  text
                }
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
            ... on PrismicDepartmentPageDataBodyRichText {
              id
              slice_type
              primary {
                content {
                  richText
                }
              }
            }
            ... on PrismicDepartmentPageDataBodyIframeSlice {
              id
              slice_type
              primary {
                iframe_source {
                  text
                }
              }
            }
          }
        }
      }
      allPrismicNews(limit: 8, sort: {fields: last_publication_date, order: DESC}) {
        edges {
          node {
            uid
            url
            data {
              post_title {
                text
              }
              post_excerpt {
                text
              }
              featured_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      aspectRatio: 1.5
                      layout: FULL_WIDTH
                      placeholder: BLURRED
                      transformOptions: {cropFocus: CENTER}
                    )
                  }
                }
              }
            }
            tags
          }
        }
      }
    }
`

export default withPrismicPreview(DepartmentPageTemplate)
